.dots {
    position: fixed;
    right: 30px;
    top: 40%;
    z-index: 999;
    display: none;

    // @media (min-width: 1401px) {
    //     display: block;
    // }

    // @media (max-width: 1400px) {
    //     display: none;
    // }

    @media (max-width: 767px) {
        display: none;

        position: fixed;
        right: 85px;
        top: -70px;
        z-index: 999;
        transform: rotate(-90deg);
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #2e4914;
        border-radius: 1em;
        width: 20px;
        height: 20px;
        margin: 10px 0;

        a {
            display: inline-flex;
            border: 1px solid #fff;
            border-radius: 1em;
            background: #f2f5db;
            width: 18px;
            height: 18px;
            transition: all 0.5s ease-in-out;
        }
    }

    &-link__active {
        background: #2e4914 !important;
    }
}
