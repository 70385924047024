
.slider {
  height: 100vh;
  /* margin-top: 100px; */

  /* position: absolute; */
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  /* z-index: 3; */
}

@media (max-width: 1400px) {

  .slider {
    height: 100vh !important;
    position: inherit;
    width: 100%;
    z-index: 3;
    background: linear-gradient(180deg, #F2F5DB 0%, #FFFFFF 100%);
  }

}

.swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide {
    height: 100vh !important;
    background-size: cover !important;
  }
  
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 60px !important;
    width: 60% !important;
  }

  .swiper-pagination-bullet {
    border: 6px solid #F2F5DB !important;
    width: 24px !important;
    height: 24px !important;
    background: transparent !important;
    border-radius: 0 !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    border: 6px solid #F2F5DB !important;
    width: 24px !important;
    height: 24px !important;
    background: #F2F5DB !important;
    border-radius: 0 !important;
  }

  .youtube-link {
    font-family: "Consolas";
    font-weight: 100;
    text-shadow: #0a0a0a 2px 2px 0, #040404 -2px -2px 0, black -2px 2px 0, black 2px -2px 0;
    text-align: center;
    color: #fff;
    font-size: 22px;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    margin-top: 100px;
  }

  .youtube-link:hover {
    text-decoration: none;
  }