
.modal-wrapp {
    display: flex;
    align-items: center;
    background: rgba(46, 73, 20, 0.66);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    transition: all .3s ease-in-out;


    @media (max-width: 767px) {

        .modal-img__form, .modal-img__phone {
            display: none;
        }

        .mb-40, .mt-40 {
            margin: 5px 0;
        }

        .mb-30, .mt-30 {
            margin: 5px 0;
        }
    }
}

.modal-hidden {
    visibility: hidden;
    opacity: 0;
}

.modal-visible {
    overflow-y: scroll;
    visibility: visible;
    opacity: 1;
}

.modal-border {
    margin-top: 100px;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    background: #F2F5DB;
    border-radius: 26px;

    @media (max-width: 1400px) {
        width: 60%;
    }

    @media (max-width: 767px) {
        width: 90%;
    }
}

.modal {
    border: 1px solid #2E4914;
    background: #F2F5DB;
    border-radius: 26px;

    @media (max-width: 767px) {
        // overflow: scroll;
        // height: 400px;
    }

}

.modal-times {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100000;
}

.modal-times img {
    width: 30px;
}

.modal-title-active {
    display: inline-flex;
    position: relative;
}

.modal-title-active::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0%;
    width: 75%;
    height: 4px;
    border-radius: 1em;
    background: #2e4914;
    transition: all 0.5s ease-in-out;
}