$f-consolas: "consolas";

.services-desc  {
    // height: 100vh;
    height: auto;
    padding-top: 150px;

    @media (max-width: 1400px) {
        // height: 100vh;
        height: auto;
        position: inherit;
        width: 100%;
        z-index: auto;
        background: linear-gradient(180deg, #F2F5DB 0%, #F2F5DB 100%);
        display: flex;
        align-items: center;
    }


    @media (max-width: 1400px) and (min-width: 992px) {
        
        align-items: flex-start;
        padding-top: 110px;

        .mt-90 {
            margin-top: 30px;
        }

        .services-desc-box__img {
            height: 350px !important;
        }

        .services-desc-box__img {
            background-position: center top;
        }
    }

    @media (max-width: 767px) {
        padding-top: 30px;
        margin-top: 30px;
        height: auto;

        .button-rub {
            transform: translateY(375px);
        }
    }


    &-menu {
        font-family: $f-consolas;
        font-weight: 100;

        &-active {
            color: #000;
        }

        ul {
            li {
                a {
                }
            }
        }
    }

    &-list-items {
        font-family: $f-consolas;
        &-active {
            color: #2e4914 !important;
        }
        ul {
            li {
                .link {
                    display: inline-block;
                    color: #333333;
                }

                a {
                }
            }
        }
    }

    &-box {
        padding: 30px;
        border: 1px solid #2e4914;
        border-radius: 1em;

        @media (max-width: 1400px) and (min-width: 992px) {
            width: 70%;
            margin: 0 auto;
        }
        

        @media (max-width: 767px) {
            transform: translateY(-75px);
        }
        

        &__img {
            background-repeat: no-repeat !important;
            background-size: cover;
            background-position: center;
            border-radius: 1em;
            height: 700px;
            width: 100%;

            @media (max-width: 767px) {
                height: 300px;
                background-position: center;
                background-size: cover;
            }
        }

    }

    &__desc {
        font-family: $f-consolas;
        font-size: 20px;

        ul {

            a {
                display: inline-block;
                color: #bdc3c7;
                transition: all 0.3s ease-in-out;
    
                @media (max-width: 767px) {
                    font-size: 18px;
                }


                &:hover {
                    color: #2e4914;
                }
            }

        }



        li {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 20px;
        }
    }
}
