// $c-green-b: #2E4914;
$c-green-b: #2e4914;
$f-consolas: "Consolas";

.menu {
    font-family: $f-consolas;
    background: $c-green-b;
    // background: #2e4914bf;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
    transition: all .3s ease-in-out;

    @media (max-width: 992px) {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // padding-top: 60px;
        transform: inherit;
        position: inherit;

        .logo-wrapp {
            text-align: center;
        }

        .logo {
            img {
                width: 30%;
            }
        }
    }

    // @media (max-width: 767px) {
    //     height: inherit;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     // padding-top: 60px;
    //     transform: translate(-100%, 0);

    //     .logo-wrapp {
    //         text-align: center;
    //     }

    //     .logo {
    //         img {
    //             width: 30%;
    //         }
    //     }
    // }






    &-wrap {
        padding: 14px 0;

        @media (max-width: 1400px) and (min-width: 992px) {
            padding: 5px 0;
        }

        .row {

            @media (max-width: 992px) {
                display: flex;
                flex-direction: column;
                
                .col-lg-1 {
                    max-width: 36%;
                }
                .col-md-3 {
                    max-width: 100%;
                }
            }

            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                
                .col-lg-1 {
                    max-width: 65%;
                }
            }
        }
    }

    a {
        @media (max-width: 991px) {
            font-size: 12px;
        }
    }

    &-nav {
        &__ul {
            @media (max-width: 992px) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &_li {
                margin: 0 15px;

                @media (max-width: 992px) {
                    margin: 10px 0;
                }

                a {
                    display: inline-flex;
                    position: relative;

                    @media (max-width: 992px) {
                        font-size: 17px;
                    }

                    @media (max-width: 767px) {
                        font-size: 17px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -8px;
                        left: 0%;
                        width: 0;
                        height: 4px;
                        border-radius: 1em;
                        background: #f2f5db;
                        transition: all 0.5s ease-in-out;
                    }

                    &:hover::before {
                        width: 100%;
                    }
                }

                .menu-active {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -8px;
                        left: 0%;
                        width: 100%;
                        height: 4px;
                        border-radius: 1em;
                        background: #f2f5db;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }
    }

    .contact-info {
        @media (max-width: 767px) {
            display: none;
            font-size: 17px;
        }
    }
    .contact-info__ul {
        @media (max-width: 992px) {
            margin-top: 20px;
        }

        a {
            @media (max-width: 992px) {
                font-size: 17px;
            }


        }
    }
}

.dsp-none_991 {
    @media (max-width: 991px) {
        display: none;
    }
}

.dsp-none_1199 {
    @media (max-width: 1199px) {
        display: none;
    }
}

// toggle-menu-wrap

.toggle-menu_wrap {
    display: none;

    @media (max-width: 992px) {
        // display: block;
        transform: translate(0px, 100%);
        width: 30px;
        height: 30px;
        position: fixed;
        z-index: 999999;
        top: -15px;
        left: 15px;
    }

    .toggle-menu {
        

        @media (max-width: 992px) {
            display: flex;
            flex-direction: column;  
        }

        span {

            @media (max-width: 992px) {
                display: inline-flex;
                width: 30px;
                height: 2px;
                background: #2e4914;
                margin: 3px 0;
            }
        }
    }
}

.open-menu {
    transform: translate(0%, 0) !important;
}

.w-span {

    span {
        background: #fff !important;
    }
}