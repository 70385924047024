$f-consolas: "Consolas";

.button {

    @media (max-width: 767px) {
        margin: 5px 0;
    }

    &-learn-more {
        display: inline-flex;
        border: 1px solid #f2f5db;
        padding: 3px;
        border-radius: 5em;

        &__a {
            display: inline-flex;
            background: #f2f5db;
            padding: 10px;
            border-radius: 5em;
        }
    }

    &-rub {
        font-family: $f-consolas;
        border-radius: 5em;
        border: 1px solid #2e4914;
        padding: 3px 0px;

        @media (max-width: 767px) {
            transform: translateY(570px);
            position: relative;
            z-index: 99;
        }

        span {
            display: inline-flex;
            color: #f2f5db;
            background: #2e4914;
            font-size: 20px;
            padding: 15px 20px;
            border-radius: 5em;
            margin-left: 5px;
        }
    }

    &-arr {
        font-family: $f-consolas;
        border-radius: 5em;
        border: 1px solid #2e4914;
        padding: 5px 5px;

        span {
            display: inline-flex;
            color: #f2f5db;
            background: #2e4914;
            font-size: 20px;
            padding: 15px 49px;
            border-radius: 5em;
            margin-left: 5px;
        }
    }

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:active {
        outline: none;
    }
}
