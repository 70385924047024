
// body::-webkit-scrollbar {
//     width: 0.5em;  
//     background: #2e4914;
// }

body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-thumb {
    background-color: #2e491482;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(230, 116, 48, 0.05);
}


* {
    margin: 0;
    padding: 0;
}

body {
    // height: 9000px;
    // overflow-y: hidden;
    // height: 100vh;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    outline: none;
    text-decoration: none;

    &:hover {
        outline: none;
        text-decoration: none;
    }

    &:focus {
        outline: none;
        text-decoration: none;
    }
    
    &:active {
        outline: none;
        text-decoration: none;
    }
}

input,textarea, button {

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }
    
    &:active {
        outline: none;
    }
}


.all-layers {
    // padding-top: 108px;
    position: relative;
}

.tac {
    text-align: center;
}

.img-res {
    width: 100%;
    height: auto;
}

.flex-stretching {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    @media (min-width: 1401px) {
        justify-content: space-between;
    }
}



.all-layers, #root, body, .App, .main, .asortiment, .product, .services, .services-desc .contact {
    // background: linear-gradient(180deg, #F2F5DB 0%, #FFFFFF 100%) !important;
    background: linear-gradient(180deg, #F2F5DB 0%, #F2F5DB 100%) !important;

    @media (max-width: 1400px) {
        background: linear-gradient(180deg, #F2F5DB 0%, #F2F5DB 100%) !important;
    }
}

@media (max-width: 1400px) and (min-width: 992px) {
    .asortiment, .product, .services, .services-desc {
        height: inherit !important;
    }

    .contact {
        height: 700px !important;
        padding: 130px 0 60px 0 !important;
    }
}


.goods-title {
    font-size: 22px;
    margin: 15px 0 15px 21px;


    @media (max-width: 767px) {


        font-size: 16px;
        margin: 5px 0 5px 12px;
    }
}

.mt-90 {
    @media (max-width: 767px) {
        margin-top: 30px !important;
    }
}