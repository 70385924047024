
.main {
    height: 100vh;
    // position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    // z-index: 2;
    // background: #fff;
    padding-top: 100px;
    background: linear-gradient(180deg, #F2F5DB 0%, #FFFFFF 100%);


    @media (max-width: 1400px) and (min-width: 992px) {
        height: auto;
        position: inherit;
        width: 100%;
        z-index: 0;
        // background: #fff;
        padding-top: 82px;
        background: linear-gradient(180deg, #F2F5DB 0%, #FFFFFF 100%);


        .mt-50.mb-50.pl-30.pt-10.pb-10 {
            margin: 15px 0;
        }
    }


    @media (max-width: 992px) {
        height: auto;
        position: inherit;
        width: 100%;
        z-index: 0;
        padding-top: 100px;
        background: linear-gradient(180deg, #F2F5DB 0%, #FFFFFF 100%);
        padding-top: 0;
    }

    .description__md {

        @media (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    &-bg {
        display: flex;
        align-items: center;
        background: url("../Main/img/main.png") no-repeat;
        background-size: cover;
        height: 485px;
        border-bottom: 6px solid #2E4914;
        position: relative;


        @media (max-width: 1400px) and (min-width: 991px) {
            height: 350px;
        }

        @media (max-width: 767px) and (min-width: 200px) {
            background: url("../Main/img/main-m.png") no-repeat;
            height: 200px;
            background-position: center;
            background-size: cover;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            width: 100%;
            background: #F2F5DB;
            height: 7px;
        }
    }
}