$f-consolas: "consolas";

.asortiment {
    // position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    // z-index: 6;
    background: #fff;

    @media (max-width: 1400px) and (min-width: 992px) {
        // height: 100vh;
        
        .mt-90 {
            margin-top: 30px;
        }
    }

    @media (max-width: 768px) {
        padding-top: 30px;
    }
}

.product {
    height: 100vh;
    padding-top: 0;
    // position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: #fff;
    
    @media (max-width: 1400px) and (min-width: 992px) {
        // height: 100vh;

        .mt-90 {
            margin-top: 30px;
        }
    }

    @media (max-width: 768px) {
        padding-top: 30px;
        margin-top: 30px;
    }

}

.services {
    height: 100vh;
    padding-top: 0;
    // position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    
    @media (max-width: 1400px) and (min-width: 992px) {
        // height: 100vh;

        .mt-90 {
            margin-top: 30px;
        }
    }
    @media (max-width: 768px) {
        margin-top: 30px;
    }

}

.product,
.services,
.asortiment {
    // height: 100vh;
    height: auto;

    @media (max-width: 1400px) {
        position: inherit;
        width: 100%;
        z-index: auto;
    }

    @media (min-width: 1400px) {
        padding-top: 140px;
    }

    @media (max-width: 1400px) and (min-width: 992px) {
        display: flex;
        align-items: flex-start;
        padding-top: 110px;
    }

    @media (max-width: 768px) {
        height: auto;
    }


    &-menu {
        font-family: $f-consolas;
        font-weight: 100;

        &-active {
            color: #000;
        }

        ul {
            li {
                a {
                }
            }
        }
    }

    &-list-items {
        font-family: $f-consolas;

        @media (max-width: 1400px) and (min-width: 992px) {
            margin-bottom: 30px;
        }

        &-active {
            color: #2e4914 !important;
        }

        ul {

            @media (max-width: 767px) {
                columns: 2;
            }

            li {
                a {
                    display: inline-block;
                    color: #bdc3c7;
                    transition: all 0.3s ease-in-out;

                    @media (max-width: 1400px) and (min-width: 992px) {
                        margin-bottom: 10px;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                    }

                    &:hover {
                        color: #2e4914;
                    }
                }
            }
        }
    }

    &-box {
        padding: 30px;
        border: 1px solid #2e4914;
        border-radius: 1em;

        @media (max-width: 1400px) and (min-width: 992px) {
            width: 70%;
            margin: 0 auto;
        }

        @media (max-width: 767px) {
            transform: translateY(-65px);
        }
        

        &__img {
            background-repeat: no-repeat !important;
            background-size: cover;
            background-position: center;
            border-radius: 1em;
            height: 370px;
            width: 100%;

            @media (max-width: 1400px) and (min-width: 992px) {
                height: 240px;
            }
            
            @media (max-width: 767px) {
                height: 300px;
            }
            
        }

        &__desc {
            font-family: $f-consolas;
            overflow: hidden;

            ::-webkit-scrollbar {
                width: 0.3em;
            }

            ::-webkit-scrollbar-thumb {
                background-color: #2e491482;
            }

            ::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(230, 116, 48, 0.05);
            }

            &_scroll {
                height: 260px;
                overflow-y: scroll;
                font-size: 18px;
                line-height: 1.5;

                @media (max-width: 1400px) and (min-width: 992px) {
                    height: 180px;
                    font-size: 14px;
                }

                @media (max-width: 767px) {
                    height: 170px;
                    font-size: 14px;
                }

                p {
                    font-size: 20px;
                    margin: 10px 0;
                }
            }
        }
    }
}

.asortiment-menu {
    a {
        transition: all 0.3s ease-in-out;
        &:hover {
            color: #2e4914 !important;
        }
    }
}

.asortiment-menu-active {
    display: inline-flex;
    position: relative;
    color: #2e4914 !important;
    transition: all 0.3s ease-in-out;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 25px;
    //     left: 0;
    //     width: 70%;
    //     height: 3px;
    //     background: #2e4914;
    // }
}
